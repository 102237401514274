import {
  AuthenticatedShop,
  PrimaryFieldBehavior,
  SecondaryFieldBehavior,
  ShopifyUser,
  ShopsPackageUnitChoices,
  ShopsPackageWeightUnitChoices,
  SystemStage,
} from 'gql'

export const defaultShop: AuthenticatedShop = {
  id: '',
  uuid: '',
  url: '',
  shopifyAdminUrl: '',
  isUitestShop: true,
  primaryDomain: '',
  defaultPortalUrl: '',
  adminAppUrl: '',
  authenticateUserUrl: '',
  enableCustomRmaNumber: false,
  isRestockIntegrationEnabled: false,
  isRefundIntegrationEnabled: false,
  exchangeIntegration: false,
  isExchangeIntegrationEnabled: false,
  isInlineExchangeEnabled: false,
  storeCreditIntegration: false,
  isStoreCreditIntegrationEnabled: false,
  isStoreCreditIncentiveEnabled: false,
  storeCreditIncentivePercentage: 0,
  isFullCatalogExchangeEnabled: false,
  exchangeDaysToReserveStock: 14,
  exchangeSamePriceOnly: false,
  refundPortalDisplayOrder: 0,
  exchangePortalDisplayOrder: 1,
  creditPortalDisplayOrder: 2,
  allowOutOfStockExchanges: false,
  enableRefund: true,
  refundDays: 30,
  enableExchange: true,
  exchangeDays: 30,
  enableStoreCredit: true,
  storeCreditDays: 30,
  notificationEmailAddress: '',
  notificationEmailAttachRma: true,
  instructions: '',
  customerEmailReplyToAddress: '',
  returnReasons: [{ id: 1, reason: 'Wrong Item' }],
  resolvePartialExchangeRefundsToStoreCredit: false,
  stages: [
    {
      id: '1',
      uuid: '1',
      systemStage: SystemStage.AutomaticallyApproved,
      label: 'Automatically Approved',
      sendEmail: false,
      emailSubject: '',
      emailContent: '',
      attachRmaForm: false,
      archived: false,
    },
  ],
  defaultAddress: {
    id: 1,
    name: 'Returns',
    company: 'Company',
    addressLine1: '123 Main Street',
    addressLine2: '',
    city: 'City',
    state: 'FL',
    postalCode: '33470',
    country: 'US',
    phoneNumber: '',
  },
  rules: [],
  packages: [
    {
      id: '1',
      uuid: 'c7yqQqoLcKLE8qf534UZrw',
      name: 'Package One',
      type: 'box',
      width: 12.0,
      height: 1.0,
      length: 12.0,
      unit: ShopsPackageUnitChoices.In,
      weight: 1.0,
      weightUnit: ShopsPackageWeightUnitChoices.Ounces,
    },
    {
      id: '2',
      uuid: 'KJYbxE4wGeSbBWdShSLWWW',
      name: 'Package Two',
      type: 'box',
      width: 20.0,
      height: 3.0,
      length: 7.0,
      unit: ShopsPackageUnitChoices.In,
      weight: 2.0,
      weightUnit: ShopsPackageWeightUnitChoices.Ounces,
    },
  ],
  shipFree: false,
  shipPay: false,
  shipSelf: false,
  defaultWeight: '3.0',
  defaultWeightUnit: 'g',
  international: false,
  internationalOriginCountry: 'US',
  internationalCustomsSigner: 'John Doe',
  internationalNonDeliveryOption: 'abandon',
  internationalExemptionCode: 'abcd',
  easypostApiKey: '',
  stripePublishableKey: '',
  stripeSecretKey: '',
  subscriptionPlan: {
    id: 0,
    uuid: '0',
    name: 'FREE',
    subscriptionType: 0,
    price: 10,
    published: false,
    features: [],
    subscribeUrl: '',
  },
  installedAt: null,
  onboardingCompletedAt: null,
  freeTrialDaysRemaining: 0,
  features: [],
  useApprovals: false,
  requireApprovalForAllReturns: false,
  primaryFieldBehavior: PrimaryFieldBehavior.EmailAddress,
  secondaryFieldBehavior: SecondaryFieldBehavior.ShopifyOrderNumber,
  portalTheme: '',
  portalLanguage: '',
  portalCustomizations: '{}',
  isShippingLabelUploadAllowed: true,
  currency: 'USD',
  isCartonizationEnabled: false,
  isDefaultFeeEqualsShippingCost: false,
  isNoShippingReturnEnabled: false,
  isQrCodeEnabled: true,
  shouldDisplayFeesInPortal: true,
  useConsolidatedOrderExchanges: true,
  isStoreCreditIncentiveRoundToNearest: false,
  isReturnItemExpirationEnabled: false,
  returnItemExpirationDays: 30,
  isExchangeDifferentialPricingEnabled: false,
  klaviyoApiKey: '',
}

export const defaultShopifyUser: ShopifyUser = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  accountOwner: false,
  locale: '',
}
