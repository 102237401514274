import { Card, Icon, Tooltip, useBreakpoints } from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectAllShopStagesSorted, selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { DashboardQuery, Maybe, SystemStage } from 'gql'
import { DEFAULT_EASING } from 'onboarding/onboarding-styles'
import { notEmpty } from 'utils'

import { ChartName } from './ChartName'
import { DataRow } from './DataRow'
import { DataRows } from './DataRows'
import { ReferenceColor } from './ReferenceColor'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'
import { CHART_PLACEHOLDER_COLOR, getStagePaletteIndex, STAGE_PALETTE } from './dashboard-values'

type StageSlice = Exclude<Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['stage'], undefined | null>
type StageChartProps = {
  stageData: StageSlice
}

type EnrichedStage = StageSlice[number] & {
  start: number
  end: number
  systemStage?: Maybe<SystemStage>
  customIndex: number
  percent: number
}

export const StageChart: FC<StageChartProps> = ({ stageData }) => {
  const { lgDown, mdDown } = useBreakpoints()
  const fmt = _(selectFormatter)
  const stagesSorted = _(selectAllShopStagesSorted)

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)

  const extendedStageData = useMemo<EnrichedStage[] | undefined>(() => {
    if (stagesSorted) {
      const totalCount = stageData.map((s) => s!.count!).reduce((result, count) => result + count, 0)
      let ratioDone = 0
      return stagesSorted
        .map((s) => {
          const stageDataEntry = stageData.find((sd) => sd?.stageId === s.id)
          if (stageDataEntry)
            return {
              ...stageDataEntry,
              start: ratioDone,
              end: (ratioDone += stageDataEntry.count! / totalCount),
              systemStage: s.systemStage,
              customIndex: 0,
              percent: Math.round((stageDataEntry.count! / totalCount) * 100),
            }
        })
        .filter(notEmpty)
    }
  }, [stageData, stagesSorted])

  return (
    <Container columnSpan={mdDown ? 6 : lgDown ? 3 : 2}>
      <Card>
        <ChartName>
          {fmt('global.stage')}
          <Tooltip
            width="wide"
            padding="400"
            preferredPosition="above"
            content={
              <FormattedMessage
                id="stageChart.tooltip"
                defaultMessage="This shows the current stage of all returns created during the selected time frame."
              />
            }
          >
            <IconContainer>
              <Icon source={InfoIcon} tone="subdued" />
            </IconContainer>
          </Tooltip>
        </ChartName>
        <Chart>
          {extendedStageData?.length ? (
            extendedStageData.map((s, i) => (
              <Segment
                key={i}
                start={s.start}
                end={s.end}
                $highlighted={i === highlightedIndex}
                onMouseEnter={() => setHighlightedIndex(i)}
                onMouseLeave={() => setHighlightedIndex((current) => (current === i ? null : current))}
              >
                <ReferenceColor index={getStagePaletteIndex(s.customIndex, s.systemStage)} palette={STAGE_PALETTE} />
              </Segment>
            ))
          ) : (
            <SegmentsPlaceholder />
          )}
        </Chart>
        <DataRows>
          {extendedStageData?.length ? (
            extendedStageData.map((s, i) => (
              <DataRow
                key={i}
                index={getStagePaletteIndex(s.customIndex, s.systemStage)}
                name={s.label}
                value={`${s.count} (${s.percent}%)`}
                palette={STAGE_PALETTE}
                highlight={i === highlightedIndex}
                onActive={() => setHighlightedIndex(i)}
                onLeft={() => setHighlightedIndex((current) => (current === i ? null : current))}
              />
            ))
          ) : (
            <ReferencesPlaceholder />
          )}
        </DataRows>
      </Card>
    </Container>
  )
}

const Container = styled.div<{ columnSpan: number }>`
  grid-column: span ${(p) => p.columnSpan};
  ${ChartName} {
    margin-top: -0.2em;
  }
`

const Chart = styled.div`
  margin-top: var(--p-space-200);
  width: 100%;
  height: 6px;
  position: relative;
`

const SegmentsPlaceholder = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  background: ${CHART_PLACEHOLDER_COLOR};
`

const Segment = styled.div<{ start: number; end: number; $highlighted: boolean }>`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  overflow: hidden;
  pointer-events: none;
  transition: transform 0.1s ${DEFAULT_EASING};
  ${ReferenceColor} {
    pointer-events: all;
    left: ${(p) => p.start * 100}%;
    right: ${(p) => (1 - p.end) * 100}%;
  }
  ${(p) =>
    p.$highlighted &&
    css`
      transform: scaleY(calc(8 / 6));
    `}
`

const IconContainer = styled.span`
  margin-left: 0.4em;
  opacity: 0.5;
  vertical-align: middle;
  .Polaris-Icon {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    top: -0.1em;
  }
`
