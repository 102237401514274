import { createSelector } from '@reduxjs/toolkit'
import { SelectOption } from '@shopify/polaris'
import { createIntl, createIntlCache, IntlShape, PrimitiveType } from 'react-intl'
import { RootState } from 'store/store'

import { getCurrencySymbol } from 'common/getCurrencySymbol'
import { ReturnReason, ReturnType as _ReturnType, Stage, SystemStage } from 'gql'
import { getLocaleKey, getMessagesForLocale, LocaleKey, supportedLocales } from 'intl/intl'
import { ShopFeature } from 'shop.context'
import { notEmpty } from 'utils'

import { GlobalSlice } from './global.slice'

const selectGlobal = (s: RootState) => s.global

export const selectLoaded = createSelector([selectGlobal], (shop) => shop.loaded)
export const selectDataLoaded = (id: GlobalSlice['loaded'][number] = 'common') =>
  createSelector([selectLoaded], (loaded) => loaded.includes(id))

export const selectUser = createSelector([selectGlobal], (global) => global.user)

export const selectGlobalToastMessage = createSelector([selectGlobal], (global) => global.toastMessage)

export const selectShop = createSelector([selectGlobal], (global) => global.shop)
export const selectShouldAuthenticateUser = createSelector(
  [selectGlobal],
  (global) => global.shop.id && !global.user?.id,
)
export const selectIsBeaconVisible = createSelector([selectGlobal], (global) => global.beaconVisible)

export const selectShopInstallDate = createSelector([selectShop], (shop) => shop.installDate)
export const selectShopCurrency = createSelector([selectShop], (shop) => shop.currency)
export const selectCustomRMAsEnabled = createSelector([selectShop], (shop) => shop.enableCustomRmaNumber)
export const selectAllShopStages = createSelector([selectShop], (shop) => shop.stages?.filter(notEmpty))
export const selectIsShippingLabelUploadAllowed = createSelector(
  [selectShop],
  (shop) => shop.isShippingLabelUploadAllowed,
)
export const selectIsNoShippingReturnEnabled = createSelector([selectShop], (shop) => shop.isNoShippingReturnEnabled)
export const selectShopRefundsEnabled = createSelector([selectShop], (shop) => shop.enableRefund)
export const selectShopExchangesEnabled = createSelector([selectShop], (shop) => shop.enableExchange)
export const selectShopStoreCreditEnabled = createSelector([selectShop], (shop) => shop.enableStoreCredit)
export const selectShopReturnTypes = createSelector(
  [selectShopRefundsEnabled, selectShopExchangesEnabled, selectShopStoreCreditEnabled],
  (refunds, exchanges, storeCredit) => {
    const types: _ReturnType[] = []
    if (refunds) types.push(_ReturnType.Refund)
    if (exchanges) types.push(_ReturnType.Exchange)
    if (storeCredit) types.push(_ReturnType.Credit)
    return types
  },
)
export const selectShopStages = createSelector([selectShop], (shop) =>
  shop.stages?.filter(notEmpty).filter((s) => !s.archived),
)
export const selectShopStagesById = createSelector([selectShopStages], (stages) =>
  stages?.filter(notEmpty).reduce<{ [id: string]: Stage }>((result, s) => ({ ...result, [s.id]: s }), {}),
)
export const selectShopStage = (id: string) => createSelector([selectShopStagesById], (stagesById) => stagesById?.[id])
export const selectShopSystemStages = createSelector([selectShopStages], (stages) => {
  const systemStages = stages?.filter(notEmpty).filter((s) => !!s.systemStage)
  if (!systemStages) return systemStages
  const sorted = [
    SystemStage.PendingApproval,
    SystemStage.Rejected,
    SystemStage.AutomaticallyApproved,
    SystemStage.Approved,
    SystemStage.Received,
  ]
  return sorted.map((ssName) => systemStages.find((ss) => ss.systemStage === ssName)).filter(notEmpty)
})
export const selectSystemStagesBySystemStage = createSelector([selectShopSystemStages], (systemStages) =>
  systemStages?.reduce<{ [ss: string]: Stage }>((result, ss) => ({ ...result, [ss.systemStage!]: ss }), {}),
)
export const selectSystemStage = (ss: SystemStage) =>
  createSelector([selectSystemStagesBySystemStage], (bySystemStage) => bySystemStage?.[ss as string])
export const selectShopCustomStages = createSelector([selectShopStages], (stages) =>
  stages?.filter(notEmpty).filter((s) => !s.systemStage),
)
export const selectAllShopStagesSorted = createSelector([selectAllShopStages], (stages) => {
  if (stages) {
    const result = [
      SystemStage.PendingApproval,
      SystemStage.AutomaticallyApproved,
      SystemStage.Approved,
      SystemStage.Received,
    ]
      .map((ss) => stages.find((s) => s.systemStage === ss))
      .filter(notEmpty)
      .concat(stages.filter((s) => !s.systemStage))
      .concat([stages.find((s) => s.systemStage === SystemStage.Rejected)].filter(notEmpty))
      .map((s) => ({ ...s, customIndex: 0 }))
    result
      .filter((s) => !s.systemStage)
      .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
      .forEach((s, i) => (s.customIndex = i))
    return result
  }
})
export const selectAllShopStagesSortedById = createSelector([selectAllShopStagesSorted], (stages) =>
  stages
    ?.filter(notEmpty)
    .reduce<{ [id: string]: (typeof stages)[number] }>((result, s) => ({ ...result, [s.id]: s }), {}),
)

export const selectRefundDisplayOrder = createSelector([selectShop], (s) => s.refundPortalDisplayOrder)
export const selectExchangeDisplayOrder = createSelector([selectShop], (s) => s.exchangePortalDisplayOrder)
export const selectCreditDisplayOrder = createSelector([selectShop], (s) => s.creditPortalDisplayOrder)

export const selectShopReasons = createSelector([selectShop], (shop) => shop.returnReasons)
export const selectShopReasonsById = createSelector([selectShopReasons], (reasons) =>
  reasons
    ?.filter(notEmpty)
    .reduce<{ [id: ReturnReason['id']]: ReturnReason }>((result, r) => ({ ...result, [r.id]: r }), {}),
)

export const selectShopFeatures = createSelector([selectShop], (shop) => shop.features)
export const selectShopHasFeature = (feature: ShopFeature) =>
  createSelector([selectShop], (shop) => shop.features?.includes(feature))
export const selectShopHasLabelIntegration = createSelector([selectShop], (shop) => {
  const hasSendcloudIntegration = !!shop.sendcloudAccounts && shop.sendcloudAccounts.length > 0
  const hasEasyPostIntegration = !!shop.easypostApiKey
  const hasShippoIntegration = !!shop.shippoApiKey
  return hasEasyPostIntegration || hasSendcloudIntegration || hasShippoIntegration
})

export const selectDefaultRefundFee = createSelector([selectShop], (shop) => shop.defaultRefundFee)
export const selectDefaultStoreCreditFee = createSelector([selectShop], (shop) => shop.defaultStoreCreditFee)

const selectDetectedLocale = createSelector([selectGlobal], (global) => global.detectedLocale)
const selectShopifyLocale = createSelector([selectUser], (user) => getLocaleKey(user?.locale))
export const selectPreferredLocale = createSelector([selectGlobal], (global) => global.preferredLocale)

export const selectLocale = createSelector(
  [selectPreferredLocale, selectShopifyLocale, selectDetectedLocale],
  (user, shopify, detected) => user || shopify || detected,
)
export const selectLocalizedShopCurrencySymbol = (negative = false) =>
  createSelector([selectLocale, selectShopCurrency], (locale, currency) =>
    getCurrencySymbol(locale, currency, negative),
  )

export const selectMessages = createSelector([selectLocale], (locale) => getMessagesForLocale(locale))

const selectIntl = createSelector([selectLocale, selectMessages], (locale, messages) =>
  createIntl({ locale, messages }, createIntlCache()),
)

export const selectFormatter = createSelector(
  [selectIntl],
  (intl) => (id?: LocaleKey, values?: Record<string, PrimitiveType>, defaultMessage?: string) =>
    (id && intl.formatMessage({ id, defaultMessage }, values)) || '',
)
export type Formatter = ReturnType<typeof selectFormatter>

export const selectRichFormatter = createSelector(
  [selectIntl],
  (intl) => (id?: LocaleKey, values?: Parameters<IntlShape['formatMessage']>[1]) =>
    (id && intl.formatMessage({ id }, values)) || '',
)
export type RichFormatter = ReturnType<typeof selectRichFormatter>

export const selectCountryOptions = createSelector([selectFormatter], (fmt) => [
  { label: '', value: '' },
  { label: fmt('countries.AF'), value: 'AF' },
  { label: fmt('countries.AX'), value: 'AX' },
  { label: fmt('countries.AL'), value: 'AL' },
  { label: fmt('countries.DZ'), value: 'DZ' },
  { label: fmt('countries.AS'), value: 'AS' },
  { label: fmt('countries.AD'), value: 'AD' },
  { label: fmt('countries.AO'), value: 'AO' },
  { label: fmt('countries.AI'), value: 'AI' },
  { label: fmt('countries.AQ'), value: 'AQ' },
  { label: fmt('countries.AG'), value: 'AG' },
  { label: fmt('countries.AR'), value: 'AR' },
  { label: fmt('countries.AM'), value: 'AM' },
  { label: fmt('countries.AW'), value: 'AW' },
  { label: fmt('countries.AU'), value: 'AU' },
  { label: fmt('countries.AT'), value: 'AT' },
  { label: fmt('countries.AZ'), value: 'AZ' },
  { label: fmt('countries.BS'), value: 'BS' },
  { label: fmt('countries.BH'), value: 'BH' },
  { label: fmt('countries.BD'), value: 'BD' },
  { label: fmt('countries.BB'), value: 'BB' },
  { label: fmt('countries.BY'), value: 'BY' },
  { label: fmt('countries.BE'), value: 'BE' },
  { label: fmt('countries.BZ'), value: 'BZ' },
  { label: fmt('countries.BJ'), value: 'BJ' },
  { label: fmt('countries.BM'), value: 'BM' },
  { label: fmt('countries.BT'), value: 'BT' },
  { label: fmt('countries.BO'), value: 'BO' },
  { label: fmt('countries.BA'), value: 'BA' },
  { label: fmt('countries.BW'), value: 'BW' },
  { label: fmt('countries.BV'), value: 'BV' },
  { label: fmt('countries.BR'), value: 'BR' },
  { label: fmt('countries.IO'), value: 'IO' },
  { label: fmt('countries.BN'), value: 'BN' },
  { label: fmt('countries.BG'), value: 'BG' },
  { label: fmt('countries.BF'), value: 'BF' },
  { label: fmt('countries.BI'), value: 'BI' },
  { label: fmt('countries.KH'), value: 'KH' },
  { label: fmt('countries.CM'), value: 'CM' },
  { label: fmt('countries.CA'), value: 'CA' },
  { label: fmt('countries.CV'), value: 'CV' },
  { label: fmt('countries.KY'), value: 'KY' },
  { label: fmt('countries.CF'), value: 'CF' },
  { label: fmt('countries.TD'), value: 'TD' },
  { label: fmt('countries.CL'), value: 'CL' },
  { label: fmt('countries.CN'), value: 'CN' },
  { label: fmt('countries.CX'), value: 'CX' },
  { label: fmt('countries.CC'), value: 'CC' },
  { label: fmt('countries.CO'), value: 'CO' },
  { label: fmt('countries.KM'), value: 'KM' },
  { label: fmt('countries.CG'), value: 'CG' },
  { label: fmt('countries.CD'), value: 'CD' },
  { label: fmt('countries.CK'), value: 'CK' },
  { label: fmt('countries.CR'), value: 'CR' },
  { label: fmt('countries.CI'), value: 'CI' },
  { label: fmt('countries.HR'), value: 'HR' },
  { label: fmt('countries.CU'), value: 'CU' },
  { label: fmt('countries.CY'), value: 'CY' },
  { label: fmt('countries.CZ'), value: 'CZ' },
  { label: fmt('countries.DK'), value: 'DK' },
  { label: fmt('countries.DJ'), value: 'DJ' },
  { label: fmt('countries.DM'), value: 'DM' },
  { label: fmt('countries.DO'), value: 'DO' },
  { label: fmt('countries.EC'), value: 'EC' },
  { label: fmt('countries.EG'), value: 'EG' },
  { label: fmt('countries.SV'), value: 'SV' },
  { label: fmt('countries.GQ'), value: 'GQ' },
  { label: fmt('countries.ER'), value: 'ER' },
  { label: fmt('countries.EE'), value: 'EE' },
  { label: fmt('countries.ET'), value: 'ET' },
  { label: fmt('countries.FK'), value: 'FK' },
  { label: fmt('countries.FO'), value: 'FO' },
  { label: fmt('countries.FJ'), value: 'FJ' },
  { label: fmt('countries.FI'), value: 'FI' },
  { label: fmt('countries.FR'), value: 'FR' },
  { label: fmt('countries.GF'), value: 'GF' },
  { label: fmt('countries.PF'), value: 'PF' },
  { label: fmt('countries.TF'), value: 'TF' },
  { label: fmt('countries.GA'), value: 'GA' },
  { label: fmt('countries.GM'), value: 'GM' },
  { label: fmt('countries.GE'), value: 'GE' },
  { label: fmt('countries.DE'), value: 'DE' },
  { label: fmt('countries.GH'), value: 'GH' },
  { label: fmt('countries.GI'), value: 'GI' },
  { label: fmt('countries.GR'), value: 'GR' },
  { label: fmt('countries.GL'), value: 'GL' },
  { label: fmt('countries.GD'), value: 'GD' },
  { label: fmt('countries.GP'), value: 'GP' },
  { label: fmt('countries.GU'), value: 'GU' },
  { label: fmt('countries.GT'), value: 'GT' },
  { label: fmt('countries.GG'), value: 'GG' },
  { label: fmt('countries.GN'), value: 'GN' },
  { label: fmt('countries.GW'), value: 'GW' },
  { label: fmt('countries.GY'), value: 'GY' },
  { label: fmt('countries.HT'), value: 'HT' },
  { label: fmt('countries.HM'), value: 'HM' },
  { label: fmt('countries.VA'), value: 'VA' },
  { label: fmt('countries.HN'), value: 'HN' },
  { label: fmt('countries.HK'), value: 'HK' },
  { label: fmt('countries.HU'), value: 'HU' },
  { label: fmt('countries.IS'), value: 'IS' },
  { label: fmt('countries.IN'), value: 'IN' },
  { label: fmt('countries.ID'), value: 'ID' },
  { label: fmt('countries.IR'), value: 'IR' },
  { label: fmt('countries.IQ'), value: 'IQ' },
  { label: fmt('countries.IE'), value: 'IE' },
  { label: fmt('countries.IM'), value: 'IM' },
  { label: fmt('countries.IL'), value: 'IL' },
  { label: fmt('countries.IT'), value: 'IT' },
  { label: fmt('countries.JM'), value: 'JM' },
  { label: fmt('countries.JP'), value: 'JP' },
  { label: fmt('countries.JE'), value: 'JE' },
  { label: fmt('countries.JO'), value: 'JO' },
  { label: fmt('countries.KZ'), value: 'KZ' },
  { label: fmt('countries.KE'), value: 'KE' },
  { label: fmt('countries.KI'), value: 'KI' },
  { label: fmt('countries.KP'), value: 'KP' },
  { label: fmt('countries.KR'), value: 'KR' },
  { label: fmt('countries.KW'), value: 'KW' },
  { label: fmt('countries.KG'), value: 'KG' },
  { label: fmt('countries.LA'), value: 'LA' },
  { label: fmt('countries.LV'), value: 'LV' },
  { label: fmt('countries.LB'), value: 'LB' },
  { label: fmt('countries.LS'), value: 'LS' },
  { label: fmt('countries.LR'), value: 'LR' },
  { label: fmt('countries.LY'), value: 'LY' },
  { label: fmt('countries.LI'), value: 'LI' },
  { label: fmt('countries.LT'), value: 'LT' },
  { label: fmt('countries.LU'), value: 'LU' },
  { label: fmt('countries.MO'), value: 'MO' },
  { label: fmt('countries.MK'), value: 'MK' },
  { label: fmt('countries.MG'), value: 'MG' },
  { label: fmt('countries.MW'), value: 'MW' },
  { label: fmt('countries.MY'), value: 'MY' },
  { label: fmt('countries.MV'), value: 'MV' },
  { label: fmt('countries.ML'), value: 'ML' },
  { label: fmt('countries.MT'), value: 'MT' },
  { label: fmt('countries.MH'), value: 'MH' },
  { label: fmt('countries.MQ'), value: 'MQ' },
  { label: fmt('countries.MR'), value: 'MR' },
  { label: fmt('countries.MU'), value: 'MU' },
  { label: fmt('countries.YT'), value: 'YT' },
  { label: fmt('countries.MX'), value: 'MX' },
  { label: fmt('countries.FM'), value: 'FM' },
  { label: fmt('countries.MD'), value: 'MD' },
  { label: fmt('countries.MC'), value: 'MC' },
  { label: fmt('countries.MN'), value: 'MN' },
  { label: fmt('countries.MS'), value: 'MS' },
  { label: fmt('countries.MA'), value: 'MA' },
  { label: fmt('countries.MZ'), value: 'MZ' },
  { label: fmt('countries.MM'), value: 'MM' },
  { label: fmt('countries.NA'), value: 'NA' },
  { label: fmt('countries.NR'), value: 'NR' },
  { label: fmt('countries.NP'), value: 'NP' },
  { label: fmt('countries.NL'), value: 'NL' },
  { label: fmt('countries.AN'), value: 'AN' },
  { label: fmt('countries.NC'), value: 'NC' },
  { label: fmt('countries.NZ'), value: 'NZ' },
  { label: fmt('countries.NI'), value: 'NI' },
  { label: fmt('countries.NE'), value: 'NE' },
  { label: fmt('countries.NG'), value: 'NG' },
  { label: fmt('countries.NU'), value: 'NU' },
  { label: fmt('countries.NF'), value: 'NF' },
  { label: fmt('countries.MP'), value: 'MP' },
  { label: fmt('countries.NO'), value: 'NO' },
  { label: fmt('countries.OM'), value: 'OM' },
  { label: fmt('countries.PK'), value: 'PK' },
  { label: fmt('countries.PW'), value: 'PW' },
  { label: fmt('countries.PS'), value: 'PS' },
  { label: fmt('countries.PA'), value: 'PA' },
  { label: fmt('countries.PG'), value: 'PG' },
  { label: fmt('countries.PY'), value: 'PY' },
  { label: fmt('countries.PE'), value: 'PE' },
  { label: fmt('countries.PH'), value: 'PH' },
  { label: fmt('countries.PN'), value: 'PN' },
  { label: fmt('countries.PL'), value: 'PL' },
  { label: fmt('countries.PT'), value: 'PT' },
  { label: fmt('countries.PR'), value: 'PR' },
  { label: fmt('countries.QA'), value: 'QA' },
  { label: fmt('countries.RE'), value: 'RE' },
  { label: fmt('countries.RO'), value: 'RO' },
  { label: fmt('countries.RU'), value: 'RU' },
  { label: fmt('countries.RW'), value: 'RW' },
  { label: fmt('countries.SH'), value: 'SH' },
  { label: fmt('countries.KN'), value: 'KN' },
  { label: fmt('countries.LC'), value: 'LC' },
  { label: fmt('countries.PM'), value: 'PM' },
  { label: fmt('countries.VC'), value: 'VC' },
  { label: fmt('countries.WS'), value: 'WS' },
  { label: fmt('countries.SM'), value: 'SM' },
  { label: fmt('countries.ST'), value: 'ST' },
  { label: fmt('countries.SA'), value: 'SA' },
  { label: fmt('countries.SN'), value: 'SN' },
  { label: fmt('countries.CS'), value: 'CS' },
  { label: fmt('countries.SC'), value: 'SC' },
  { label: fmt('countries.SL'), value: 'SL' },
  { label: fmt('countries.SG'), value: 'SG' },
  { label: fmt('countries.SK'), value: 'SK' },
  { label: fmt('countries.SI'), value: 'SI' },
  { label: fmt('countries.SB'), value: 'SB' },
  { label: fmt('countries.SO'), value: 'SO' },
  { label: fmt('countries.ZA'), value: 'ZA' },
  { label: fmt('countries.GS'), value: 'GS' },
  { label: fmt('countries.ES'), value: 'ES' },
  { label: fmt('countries.LK'), value: 'LK' },
  { label: fmt('countries.SD'), value: 'SD' },
  { label: fmt('countries.SR'), value: 'SR' },
  { label: fmt('countries.SJ'), value: 'SJ' },
  { label: fmt('countries.SZ'), value: 'SZ' },
  { label: fmt('countries.SE'), value: 'SE' },
  { label: fmt('countries.CH'), value: 'CH' },
  { label: fmt('countries.SY'), value: 'SY' },
  { label: fmt('countries.TW'), value: 'TW' },
  { label: fmt('countries.TJ'), value: 'TJ' },
  { label: fmt('countries.TZ'), value: 'TZ' },
  { label: fmt('countries.TH'), value: 'TH' },
  { label: fmt('countries.TL'), value: 'TL' },
  { label: fmt('countries.TG'), value: 'TG' },
  { label: fmt('countries.TK'), value: 'TK' },
  { label: fmt('countries.TO'), value: 'TO' },
  { label: fmt('countries.TT'), value: 'TT' },
  { label: fmt('countries.TN'), value: 'TN' },
  { label: fmt('countries.TR'), value: 'TR' },
  { label: fmt('countries.TM'), value: 'TM' },
  { label: fmt('countries.TC'), value: 'TC' },
  { label: fmt('countries.TV'), value: 'TV' },
  { label: fmt('countries.UG'), value: 'UG' },
  { label: fmt('countries.UA'), value: 'UA' },
  { label: fmt('countries.AE'), value: 'AE' },
  { label: fmt('countries.GB'), value: 'GB' },
  { label: fmt('countries.US'), value: 'US' },
  { label: fmt('countries.UM'), value: 'UM' },
  { label: fmt('countries.UY'), value: 'UY' },
  { label: fmt('countries.UZ'), value: 'UZ' },
  { label: fmt('countries.VU'), value: 'VU' },
  { label: fmt('countries.VE'), value: 'VE' },
  { label: fmt('countries.VN'), value: 'VN' },
  { label: fmt('countries.VG'), value: 'VG' },
  { label: fmt('countries.VI'), value: 'VI' },
  { label: fmt('countries.WF'), value: 'WF' },
  { label: fmt('countries.EH'), value: 'EH' },
  { label: fmt('countries.YE'), value: 'YE' },
  { label: fmt('countries.ZM'), value: 'ZM' },
  { label: fmt('countries.ZW'), value: 'ZW' },
])

export const COUNTRIES_THAT_REQUIRE_PROVINCE = ['US', 'CA', 'AU', 'ES', 'MX', 'IT', 'BR', 'NZ', 'KR']

export const selectLocales = createSelector([selectFormatter], (fmt) => {
  type Locale = Exclude<SelectOption, string> & {
    isRTL?: true
  }
  const locales: Locale[] = [
    { value: 'ar-LB', isRTL: true, label: fmt('locales.ar-LB') },
    { value: 'zh-Hant', label: fmt('locales.zh-Hant') },
    { value: 'cs', label: fmt('locales.cs') },
    { value: 'nl', label: fmt('locales.nl') },
    { value: 'en-GB', label: fmt('locales.en-GB') },
    { value: 'en', label: fmt('locales.en') },
    { value: 'fi', label: fmt('locales.fi') },
    { value: 'fr', label: fmt('locales.fr') },
    { value: 'de', label: fmt('locales.de') },
    { value: 'it', label: fmt('locales.it') },
    { value: 'pl', label: fmt('locales.pl') },
    { value: 'pt-BR', label: fmt('locales.pt-BR') },
    { value: 'es', label: fmt('locales.es') },
    { value: 'tr', label: fmt('locales.tr') },
  ]
  return locales
})

export const selectPortalLocales = createSelector([selectLocales], (locales) => {
  return locales.filter((l) => !['ar-LB', 'zh-Hant'].includes(l.value))
})

export const selectAdminLocales = createSelector([selectLocales], (locales) => {
  return locales.filter((l) => Object.keys(supportedLocales).includes(l.value))
})

export const selectReturnTypeOptions = createSelector([selectFormatter], (fmt) => [
  { label: '', value: '' },
  { label: fmt('returnType.REFUND.name'), value: _ReturnType.Refund },
  { label: fmt('returnType.EXCHANGE.name'), value: _ReturnType.Exchange },
  { label: fmt('returnType.CREDIT.name'), value: _ReturnType.Credit },
])

export const selectThemeOptions = createSelector([selectFormatter], (fmt) => {
  const themes: SelectOption[] = [
    { value: 'light', label: fmt('themes.light') },
    { value: 'dark', label: fmt('themes.dark') },
  ]
  return themes
})

export const selectShopifyLocations = createSelector([selectShop], (s) => s.shopifyLocations)
