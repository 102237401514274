import { Card, useBreakpoints } from '@shopify/polaris'
import { ArcElement, Chart, ChartOptions, DoughnutController } from 'chart.js'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { ChartName } from './ChartName'
import { DataRow } from './DataRow'
import { DataRows } from './DataRows'
import { DASHBOARD_PALETTE } from './ReferenceColor'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'
import { CHART_PLACEHOLDER_COLOR } from './dashboard-values'

Chart.register(DoughnutController, ArcElement)

type DonutChartData = {
  name: string
  count: number
}[]

type DonutChartProps = {
  name: ReactNode
  data: DonutChartData
}

export const DonutChart: FC<DonutChartProps> = ({ name, data }) => {
  const { lgDown, mdDown } = useBreakpoints()

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [trustHighlight, setTrustHighlight] = useState(true)

  const [enrichedData, setEnrichedData] = useState<((typeof data)[number] & { percent: number })[]>([])
  const [labels, setLabels] = useState<string[]>([])
  const [counts, setCounts] = useState<number[]>([])

  useEffect(() => {
    const totalCount = data.map((s) => s.count).reduce((result, count) => result + count, 0)
    setEnrichedData(
      data.map((s) => ({
        ...s,
        percent: Math.round((s.count / totalCount) * 100),
      })),
    )

    setLabels(data.map((row) => row.name))
    setCounts(data.map((row) => row.count))
  }, [data])

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [chart, setChart] = useState<Chart>()
  useEffect(() => {
    if (canvas) {
      const chart = new Chart(canvas, {
        type: 'doughnut',
        options: {
          cutout: 60,
          layout: {
            padding: 1,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          onHover: (event, elements) => {
            if (elements.length) setHighlightedIndex(elements[0].index)
            else setHighlightedIndex(null)
          },
          animations: {
            borderWidth: {
              duration: 100,
              easing: 'easeInOutCubic',
            },
          },
        } as ChartOptions,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              borderJoinStyle: 'miter',
              borderWidth: 0,
              borderColor: DASHBOARD_PALETTE,
              backgroundColor: DASHBOARD_PALETTE,
            },
          ],
        },
      })
      setChart(chart)
      return () => {
        setChart(undefined)
        chart.destroy()
      }
    }
  }, [canvas])

  useEffect(() => {
    if (chart?.canvas) {
      chart.data.labels = labels
      chart.data.datasets[0].data = counts

      const borderWidths = new Array(counts.length).fill(0)
      if (trustHighlight && highlightedIndex !== null) borderWidths[highlightedIndex] = 2
      chart.data.datasets[0].borderWidth = borderWidths

      chart.update()
    }
  }, [chart, labels, counts, trustHighlight, highlightedIndex])

  return (
    <Container columnSpan={mdDown ? 6 : lgDown ? 3 : 2}>
      <Card>
        <Top>
          <CanvasWrapper $gone={!enrichedData.length}>
            <canvas
              ref={setCanvas}
              onMouseEnter={() => setTrustHighlight(true)}
              onMouseLeave={() => setTrustHighlight(false)}
            ></canvas>
          </CanvasWrapper>
          {!enrichedData.length && <ChartPlaceholder />}
          <ChartName>{name}</ChartName>
        </Top>
        <DataRows>
          {enrichedData.length ? (
            enrichedData.map((s, i) => (
              <DataRow
                key={i}
                index={i}
                name={s.name}
                value={`${s.count} (${s.percent}%)`}
                highlight={trustHighlight && i === highlightedIndex}
                onActive={() => {
                  setTrustHighlight(true)
                  setHighlightedIndex(i)
                }}
                onLeft={() => setHighlightedIndex((current) => (current === i ? null : current))}
              />
            ))
          ) : (
            <ReferencesPlaceholder />
          )}
        </DataRows>
      </Card>
    </Container>
  )
}

const Top = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;
  padding-bottom: var(--p-space-500);
  border-bottom: 1px solid var(--p-color-border-secondary);
  margin-bottom: calc(var(--p-space-100) * -1);
`

const CanvasWrapper = styled.div<{ $gone: boolean }>`
  width: 134px;
  height: 134px;
  position: relative;
  ${(p) =>
    p.$gone &&
    css`
      display: none;
    `}
`

const ChartPlaceholder = styled.div`
  width: 134px;
  height: 134px;
  border: 6px solid ${CHART_PLACEHOLDER_COLOR};
  border-radius: 50%;
`

const Container = styled.div<{ columnSpan: number }>`
  grid-column: span ${(p) => p.columnSpan};
`
